import {
  LocationRoleType,
  ManagerAccountRoleType,
  type ManagerAccount,
  type UserRole,
} from "@prisma/client";
import { assertAuthorized } from "./assert.utils.server";
import { redirect, type SerializeFrom } from "@remix-run/node";
import { adminCanDo, AdminPermission } from "./admin-permission.utils";

export const RVIPermission = {
  // Dashboard
  ViewDashboard: "View Dashboard",

  // Zipline
  ViewZiplineOnboarding: "View Zipline Onboarding",
  UploadFilesZiplineOnboarding: "Upload Files Zipline Onboarding",
  ManageZiplineContracts: "Manage Zipline Contracts",

  // Reports
  ViewReports: "View Reports",
  ViewSpendByResidentPhase: "View Spend By Resident Phase",
  ViewSpendByDepartment: "View Spend By Department",
  ViewSpendByEnterpriseCategory: "View Spend By Enterprise Category",
  ViewSpendByLocation: "View Spend By Location",

  // Settings
  ViewAccountSettings: "View Account Settings",
  ManageAccountSettings: "Manage Account Settings",

  // Locations
  ViewLocationsTable: "View Locations Table",
  ViewLocationDetails: "View Location Details",
  CreateLocations: "Create Locations",
  ManageLocations: "Manage Locations",
  ExportLocationDocuments: "Export Location Documents",
  DeleteLocations: "Delete Locations",

  // Location Notices
  ViewLocationNotices: "View Location Notices",
  ViewCorporateLevelServiceTerminationNotices:
    "View Corporate Level Service Termination Notices",
  ViewLocationLevelServiceTerminationNotices:
    "View Location Service Termination Notices",
  ViewLocationDispositionNotices:
    "View Company Location Disposition Termination Notices",
  ManageLocationNotices: "Create Location Notices",
  ManageCorporateLevelServiceTerminationNotices:
    "Manage Corporate Level Location Service Termination Notices",
  ManageLocationLevelServiceTerminationNotices:
    "Manage Location ServiceTermination Notices",
  ManageLocationDispositionNotices:
    "Manage Company Location Disposition Termination Notices",

  // Vendors
  ViewVendorsTable: "View Vendors Table",
  CreateVendors: "Create Vendors",
  ManageVendors: "Manage Vendors",
  ViewVendorDetails: "View Vendor Details",
  ViewLocationLevelVendorDetails: "View Location Level Vendor Details",
  ViewCorporateLevelVendorDetails: "View Corporate Level Vendor Details",
  ViewLocationLevelVendorsTable: "View Location Level Vendors",
  ViewCorporateLevelVendorsTable: "View Corporate Level Vendors",
  ManageLocationVendors: "Manage Location Vendors",
  ManageCorporateVendors: "Manage Corporate Vendors",
  ViewLocationVendorDocuments: "View Location Vendor Documents",
  ManageLocationVendorDocuments: "Manage Location Vendor Documents",
  ViewCorporateVendorDocuments: "View Corporate Vendor Documents",
  ManageCorporateVendorDocuments: "Manage Corporate Vendor Documents",

  // Tasks
  ManageTasks: "Manage Tasks",
  ViewTasks: "View Tasks",
  ViewLocationDispositionTask: "View Location Disposition Task",
  ManageLocationDispositionTask: "Manage Location Disposition Task",
  ViewCorporateLevelContractRenewalTask:
    "View Corporate Level Contract Renewal Task",
  ManageCorporateLevelContractRenewalTask:
    "Manage Corporate Level Contract Renewal Task",
  ViewLocationLevelContractRenewalTask:
    "View Location Level Contract Renewal Task",
  ManageLocationLevelContractRenewalTask:
    "Manage Location Level Contract Renewal Task",
  ChatOnCorporateLevelContractRenewalTask:
    "Chat on Corporate Level Contract Renewal Task",
  ChatOnLocationLevelContractRenewalTask:
    "Chat on Location Level Contract Renewal Task",
  ChatOnLocationDispositionTask: "Chat on Location Disposition Task",

  // Contracts
  ViewContractsTable: "View Contracts Table",
  ViewCorporateLevelContracts: "View Corporate Level Contracts",
  ViewContractDetails: "View Contract Details",
  ViewCorporateLevelContractDetails: "View Corporate Level Contract Details",
  ViewLocationLevelContractDetails: "View Location Level Contract Details",
  ManageContracts: "Manage Contracts",
  CreateLocationContracts: "Create Location Contracts",
  CreateCorporateLevelContracts: "Create Corporate Level Contracts",
  ManageCorporateLevelContracts: "Manage Corporate Level Contracts",
  ManageLocationLevelContracts: "Manage Location Level Contracts",
  ViewContractLocations: "View Contract Locations",
  ManageContractLocationsDocuments: "Manage Contract Locations Documents",
  ViewCorporateLevelContractDocuments:
    "View Corporate Level Contract Documents",
  ViewLocationLevelContractDocuments: "View Location Level Contract Documents",

  // Contract Line Items
  ViewLocationLevelContractProductsAndContractLineItems:
    "View Location Level Line Items",
  ViewCorporateLevelContractProductsAndContractLineItems:
    "View Corporate Level Contract Line Items",
  ViewContractLineItem: "View Contract Line Item",
  ManageContractLineItem: "Manage Contract Line Item",
  ViewCorporateLevelContractLineItemPricing:
    "View Contract Line Item Pricing on Assigned Line Items table",
  ViewLocationLevelContractLineItemPricing:
    "View Location Level Contract Line Item Pricing",
  ManageCorporateLevelContractLineItemStatus:
    "Change Contract Line Item Status thru Blue Pencil CTA",
  ManageLocationLevelContractLineItemStatus:
    "Manage Location Level Contract Line Item Status",
  ViewCorporateLevelContractLineItemsInTable:
    "View Corporate Level Contract Line Items in Table",
  ViewLocationLevelContractLineItemsInTable:
    "View Location Level Contract Line Items in Table",
  ViewCorporateLevelContractLineItemDetails:
    "View Corporate Level Contract Line Item Details",
  ViewLocationLevelContractLineItemDetails:
    "View Location Level Contract Line Item Details",
  CopyContractLineItemsToLocations: "Copy Contract Line Items to Locations",
  ManageLocationLevelContractLineItem:
    "Manage Location Level Contract Line Item",
  ManageCorporateLevelContractLineItem:
    "Manage Corporate Level Contract Line Item",
  ExportContractLineItems: "Export Contract Line Items into a CSV",

  // Documents
  ViewDocumentsTable: "View Documents Table",
  ManageRVIDocuments: "Manage Documents On Documents Table",

  // Contract Line Item Locations
  ManageContractLineItemLocations: "Manage Contract Line Item Locations",
  ViewLocationVendorsAndProducts: "View Location Vendors And Products",

  // Sensitive Contracts
  ViewSensitiveContracts: "View Sensitive Contracts",
  ManageSensitiveContracts: "Manage Sensitive Contracts",

  // AI Assist
  UseAIAssist: "Use AI Assist",

  // Account
  ViewCompanyAccountInformation: "View Company Account Information",
  ManageCompanyAccountInformation: "Manage Company Account Information",
  ViewAccountInformation: "View Account Information",
  ViewAccountUsers: "View Account Users",
  ManageAccountUsers: "Manage Account Users",
  ViewRenewalRemindersTable: "View Renewal Reminders Table",

  //Clippy
  AllowClippy: "View Clippy CTA on Contract Form",

  // Entities Selector
  ViewEntitiesSelector: "View Entities Selector",
  ViewEntitiesSelectorAllOption: "View Entities Selector All Option",
  ViewEntitiesSelectorCorporateOption:
    "View Entities Selector Corporate Option",
  ViewEntitiesSelectorLocationOption: "View Entities Selector Location Option",

  // Compliance
  ManageCompliancePrograms: "Manage Compliance Programs",
  ViewCompliancePrograms: "View Compliance Programs",

  ViewComplianceRequirements: "View Compliance Requirements",
  ManageComplianceRequirements: "Manage Compliance Requirements",
  DeleteComplianceRequirements: "Delete Compliance Requirements",

  AddRequirementToComplianceProgram:
    "Add Compliance Requirements to Compliance Programs",

  // Vendor
  ManageVendorCustomer: "Manage vendor customer",
} as const;

type PermissionKeys = keyof typeof RVIPermission;
export type PermissionType = (typeof RVIPermission)[PermissionKeys];

export const RVIRolePermissionMap: Record<
  ManagerAccountRoleType,
  Readonly<PermissionType[]>
> = {
  [ManagerAccountRoleType.CompanyOwner]: [
    RVIPermission.ViewDashboard,
    RVIPermission.ViewReports,
    RVIPermission.ViewSpendByResidentPhase,
    RVIPermission.ViewSpendByDepartment,
    RVIPermission.ViewSpendByEnterpriseCategory,
    RVIPermission.ViewSpendByLocation,
    RVIPermission.ViewLocationsTable,
    RVIPermission.ViewLocationDetails,
    RVIPermission.ManageLocations,
    RVIPermission.CreateLocations,
    RVIPermission.ExportLocationDocuments,
    RVIPermission.DeleteLocations,
    RVIPermission.ViewLocationNotices,
    RVIPermission.ViewLocationDispositionNotices,
    RVIPermission.ViewLocationLevelServiceTerminationNotices,
    RVIPermission.ViewCorporateLevelServiceTerminationNotices,
    RVIPermission.ManageLocationNotices,
    RVIPermission.ManageLocationDispositionNotices,
    RVIPermission.ManageCorporateLevelServiceTerminationNotices,
    RVIPermission.ManageLocationLevelServiceTerminationNotices,
    RVIPermission.ViewLocationVendorsAndProducts,
    RVIPermission.ViewVendorsTable,
    RVIPermission.CreateVendors,
    RVIPermission.ManageVendors,
    RVIPermission.ManageLocationVendors,
    RVIPermission.ManageCorporateVendors,
    RVIPermission.ViewVendorDetails,
    RVIPermission.ViewLocationLevelVendorDetails,
    RVIPermission.ViewCorporateLevelVendorDetails,
    RVIPermission.ViewCorporateLevelVendorsTable,
    RVIPermission.ViewLocationLevelVendorsTable,
    RVIPermission.ManageCorporateVendors,
    RVIPermission.ManageLocationVendors,
    RVIPermission.ViewLocationVendorDocuments,
    RVIPermission.ManageLocationVendorDocuments,
    RVIPermission.ViewCorporateVendorDocuments,
    RVIPermission.ManageCorporateVendorDocuments,
    RVIPermission.ViewContractsTable,
    RVIPermission.ViewCorporateLevelContracts,
    RVIPermission.ViewContractDetails,
    RVIPermission.ViewCorporateLevelContractDetails,
    RVIPermission.ViewLocationLevelContractDetails,
    RVIPermission.ManageContracts,
    RVIPermission.CreateLocationContracts,
    RVIPermission.CreateCorporateLevelContracts,
    RVIPermission.ManageCorporateLevelContracts,
    RVIPermission.ManageLocationLevelContracts,
    RVIPermission.ManageContractLocationsDocuments,
    RVIPermission.ViewSensitiveContracts,
    RVIPermission.ManageSensitiveContracts,
    RVIPermission.ViewCorporateLevelContractDocuments,
    RVIPermission.ViewLocationLevelContractDocuments,
    RVIPermission.ViewContractLineItem,
    RVIPermission.ExportContractLineItems,
    RVIPermission.ViewLocationLevelContractProductsAndContractLineItems,
    RVIPermission.ViewCorporateLevelContractProductsAndContractLineItems,
    RVIPermission.ManageContractLineItem,
    RVIPermission.ManageLocationLevelContractLineItem,
    RVIPermission.ManageCorporateLevelContractLineItem,
    RVIPermission.CopyContractLineItemsToLocations,
    RVIPermission.ViewCorporateLevelContractLineItemPricing,
    RVIPermission.ViewLocationLevelContractLineItemPricing,
    RVIPermission.ManageCorporateLevelContractLineItemStatus,
    RVIPermission.ManageLocationLevelContractLineItemStatus,
    RVIPermission.ViewCorporateLevelContractLineItemsInTable,
    RVIPermission.ViewLocationLevelContractLineItemsInTable,
    RVIPermission.ViewCorporateLevelContractLineItemDetails,
    RVIPermission.ViewLocationLevelContractLineItemDetails,
    RVIPermission.ManageContractLineItemLocations,
    RVIPermission.ViewContractLocations,
    RVIPermission.ViewAccountInformation,
    RVIPermission.ViewCompanyAccountInformation,
    RVIPermission.ManageCompanyAccountInformation,
    RVIPermission.ViewAccountUsers,
    RVIPermission.ManageAccountUsers,
    RVIPermission.ManageTasks,
    RVIPermission.ViewLocationDispositionTask,
    RVIPermission.ManageLocationDispositionTask,
    RVIPermission.ViewCorporateLevelContractRenewalTask,
    RVIPermission.ManageCorporateLevelContractRenewalTask,
    RVIPermission.ViewLocationLevelContractRenewalTask,
    RVIPermission.ManageLocationLevelContractRenewalTask,
    RVIPermission.ViewTasks,
    RVIPermission.ChatOnCorporateLevelContractRenewalTask,
    RVIPermission.ChatOnLocationLevelContractRenewalTask,
    RVIPermission.ChatOnLocationDispositionTask,
    RVIPermission.ViewEntitiesSelector,
    RVIPermission.ViewEntitiesSelectorAllOption,
    RVIPermission.ViewEntitiesSelectorCorporateOption,
    RVIPermission.ViewEntitiesSelectorLocationOption,
    RVIPermission.ViewZiplineOnboarding,
    RVIPermission.UploadFilesZiplineOnboarding,
    RVIPermission.ManageZiplineContracts,
    RVIPermission.ViewAccountSettings,
    RVIPermission.ManageAccountSettings,
    RVIPermission.ViewRenewalRemindersTable,
    RVIPermission.ViewDocumentsTable,
    RVIPermission.ManageRVIDocuments,
    RVIPermission.ManageCompliancePrograms,
    RVIPermission.ViewCompliancePrograms,
    RVIPermission.ViewComplianceRequirements,
    RVIPermission.AddRequirementToComplianceProgram,
    // RVIPermission.ManageComplianceRequirements,
    // RVIPermission.DeleteComplianceRequirements,
  ],
  [ManagerAccountRoleType.CompanyAdmin]: [
    RVIPermission.ViewDashboard,
    RVIPermission.ViewReports,
    RVIPermission.ViewSpendByResidentPhase,
    RVIPermission.ViewSpendByDepartment,
    RVIPermission.ViewSpendByEnterpriseCategory,
    RVIPermission.ViewSpendByLocation,
    RVIPermission.ViewLocationsTable,
    RVIPermission.ViewLocationDetails,
    RVIPermission.ManageLocations,
    RVIPermission.CreateLocations,
    RVIPermission.ExportLocationDocuments,
    RVIPermission.DeleteLocations,
    RVIPermission.ViewLocationNotices,
    RVIPermission.ViewLocationDispositionNotices,
    RVIPermission.ViewLocationLevelServiceTerminationNotices,
    RVIPermission.ViewCorporateLevelServiceTerminationNotices,
    RVIPermission.ManageLocationNotices,
    RVIPermission.ManageLocationDispositionNotices,
    RVIPermission.ManageCorporateLevelServiceTerminationNotices,
    RVIPermission.ManageLocationLevelServiceTerminationNotices,
    RVIPermission.ViewLocationVendorsAndProducts,
    RVIPermission.ViewVendorsTable,
    RVIPermission.CreateVendors,
    RVIPermission.ManageVendors,
    RVIPermission.ManageLocationVendors,
    RVIPermission.ManageCorporateVendors,
    RVIPermission.ViewVendorDetails,
    RVIPermission.ViewLocationLevelVendorDetails,
    RVIPermission.ViewCorporateLevelVendorDetails,
    RVIPermission.ViewCorporateLevelVendorsTable,
    RVIPermission.ViewLocationLevelVendorsTable,
    RVIPermission.ManageCorporateVendors,
    RVIPermission.ManageLocationVendors,
    RVIPermission.ViewLocationVendorDocuments,
    RVIPermission.ManageLocationVendorDocuments,
    RVIPermission.ViewCorporateVendorDocuments,
    RVIPermission.ManageCorporateVendorDocuments,
    RVIPermission.ViewContractsTable,
    RVIPermission.ViewCorporateLevelContracts,
    RVIPermission.ViewContractDetails,
    RVIPermission.ViewCorporateLevelContractDetails,
    RVIPermission.ViewLocationLevelContractDetails,
    RVIPermission.ManageContracts,
    RVIPermission.CreateLocationContracts,
    RVIPermission.CreateCorporateLevelContracts,
    RVIPermission.ManageCorporateLevelContracts,
    RVIPermission.ManageLocationLevelContracts,
    RVIPermission.ManageContractLocationsDocuments,
    RVIPermission.ViewCorporateLevelContractDocuments,
    RVIPermission.ViewLocationLevelContractDocuments,
    RVIPermission.ViewContractLineItem,
    RVIPermission.ExportContractLineItems,
    RVIPermission.ViewLocationLevelContractProductsAndContractLineItems,
    RVIPermission.ViewCorporateLevelContractProductsAndContractLineItems,
    RVIPermission.ManageContractLineItem,
    RVIPermission.ManageLocationLevelContractLineItem,
    RVIPermission.ManageCorporateLevelContractLineItem,
    RVIPermission.CopyContractLineItemsToLocations,
    RVIPermission.ViewCorporateLevelContractLineItemPricing,
    RVIPermission.ViewLocationLevelContractLineItemPricing,
    RVIPermission.ManageCorporateLevelContractLineItemStatus,
    RVIPermission.ManageLocationLevelContractLineItemStatus,
    RVIPermission.ViewCorporateLevelContractLineItemsInTable,
    RVIPermission.ViewLocationLevelContractLineItemsInTable,
    RVIPermission.ViewCorporateLevelContractLineItemDetails,
    RVIPermission.ViewLocationLevelContractLineItemDetails,
    RVIPermission.ManageContractLineItemLocations,
    RVIPermission.ViewContractLocations,
    RVIPermission.ViewAccountInformation,
    RVIPermission.ManageCompanyAccountInformation,
    RVIPermission.ViewAccountUsers,
    RVIPermission.ManageAccountUsers,
    RVIPermission.ManageTasks,
    RVIPermission.ViewLocationDispositionTask,
    RVIPermission.ManageLocationDispositionTask,
    RVIPermission.ViewCorporateLevelContractRenewalTask,
    RVIPermission.ManageCorporateLevelContractRenewalTask,
    RVIPermission.ViewLocationLevelContractRenewalTask,
    RVIPermission.ManageLocationLevelContractRenewalTask,
    RVIPermission.ViewTasks,
    RVIPermission.ChatOnCorporateLevelContractRenewalTask,
    RVIPermission.ChatOnLocationLevelContractRenewalTask,
    RVIPermission.ChatOnLocationDispositionTask,
    RVIPermission.ViewEntitiesSelector,
    RVIPermission.ViewEntitiesSelectorAllOption,
    RVIPermission.ViewEntitiesSelectorCorporateOption,
    RVIPermission.ViewEntitiesSelectorLocationOption,
    RVIPermission.ViewZiplineOnboarding,
    RVIPermission.UploadFilesZiplineOnboarding,
    RVIPermission.ManageZiplineContracts,
    RVIPermission.ManageCompliancePrograms,
    RVIPermission.ViewCompliancePrograms,
    RVIPermission.ViewComplianceRequirements,
    RVIPermission.AddRequirementToComplianceProgram,
    // RVIPermission.ManageComplianceRequirements,
    // RVIPermission.DeleteComplianceRequirements,
  ],
  [ManagerAccountRoleType.CompanyEditor]: [
    RVIPermission.ViewDashboard,
    RVIPermission.ViewReports,
    RVIPermission.ViewSpendByResidentPhase,
    RVIPermission.ViewSpendByDepartment,
    RVIPermission.ViewSpendByEnterpriseCategory,
    RVIPermission.ViewSpendByLocation,
    RVIPermission.ViewLocationsTable,
    RVIPermission.ViewLocationDetails,
    RVIPermission.ManageLocations,
    RVIPermission.CreateLocations,
    RVIPermission.ExportLocationDocuments,
    RVIPermission.DeleteLocations,
    RVIPermission.ViewLocationNotices,
    RVIPermission.ViewLocationDispositionNotices,
    RVIPermission.ViewLocationLevelServiceTerminationNotices,
    RVIPermission.ViewCorporateLevelServiceTerminationNotices,
    RVIPermission.ManageLocationNotices,
    RVIPermission.ManageLocationDispositionNotices,
    RVIPermission.ManageCorporateLevelServiceTerminationNotices,
    RVIPermission.ManageLocationLevelServiceTerminationNotices,
    RVIPermission.ViewLocationVendorsAndProducts,
    RVIPermission.ViewVendorsTable,
    RVIPermission.CreateVendors,
    RVIPermission.ManageVendors,
    RVIPermission.ManageLocationVendors,
    RVIPermission.ManageCorporateVendors,
    RVIPermission.ViewVendorDetails,
    RVIPermission.ViewLocationLevelVendorDetails,
    RVIPermission.ViewCorporateLevelVendorDetails,
    RVIPermission.ViewCorporateLevelVendorsTable,
    RVIPermission.ViewLocationLevelVendorsTable,
    RVIPermission.ManageCorporateVendors,
    RVIPermission.ManageLocationVendors,
    RVIPermission.ViewLocationVendorDocuments,
    RVIPermission.ManageLocationVendorDocuments,
    RVIPermission.ViewCorporateVendorDocuments,
    RVIPermission.ManageCorporateVendorDocuments,
    RVIPermission.ViewContractsTable,
    RVIPermission.ViewCorporateLevelContracts,
    RVIPermission.ViewContractDetails,
    RVIPermission.ViewCorporateLevelContractDetails,
    RVIPermission.ViewLocationLevelContractDetails,
    RVIPermission.ManageContracts,
    RVIPermission.CreateLocationContracts,
    RVIPermission.CreateCorporateLevelContracts,
    RVIPermission.ManageCorporateLevelContracts,
    RVIPermission.ManageLocationLevelContracts,
    RVIPermission.ManageContractLocationsDocuments,
    RVIPermission.ViewCorporateLevelContractDocuments,
    RVIPermission.ViewLocationLevelContractDocuments,
    RVIPermission.ViewContractLineItem,
    RVIPermission.ExportContractLineItems,
    RVIPermission.ViewLocationLevelContractProductsAndContractLineItems,
    RVIPermission.ViewCorporateLevelContractProductsAndContractLineItems,
    RVIPermission.ManageContractLineItem,
    RVIPermission.ManageLocationLevelContractLineItem,
    RVIPermission.ManageCorporateLevelContractLineItem,
    RVIPermission.CopyContractLineItemsToLocations,
    RVIPermission.ViewCorporateLevelContractLineItemPricing,
    RVIPermission.ViewLocationLevelContractLineItemPricing,
    RVIPermission.ManageCorporateLevelContractLineItemStatus,
    RVIPermission.ManageLocationLevelContractLineItemStatus,
    RVIPermission.ViewCorporateLevelContractLineItemsInTable,
    RVIPermission.ViewLocationLevelContractLineItemsInTable,
    RVIPermission.ViewCorporateLevelContractLineItemDetails,
    RVIPermission.ViewLocationLevelContractLineItemDetails,
    RVIPermission.ManageContractLineItemLocations,
    RVIPermission.ViewContractLocations,
    RVIPermission.ManageTasks,
    RVIPermission.ViewLocationDispositionTask,
    RVIPermission.ManageLocationDispositionTask,
    RVIPermission.ViewCorporateLevelContractRenewalTask,
    RVIPermission.ManageCorporateLevelContractRenewalTask,
    RVIPermission.ViewLocationLevelContractRenewalTask,
    RVIPermission.ManageLocationLevelContractRenewalTask,
    RVIPermission.ViewTasks,
    RVIPermission.ChatOnCorporateLevelContractRenewalTask,
    RVIPermission.ChatOnLocationLevelContractRenewalTask,
    RVIPermission.ChatOnLocationDispositionTask,
    RVIPermission.ViewEntitiesSelector,
    RVIPermission.ViewEntitiesSelectorAllOption,
    RVIPermission.ViewEntitiesSelectorCorporateOption,
    RVIPermission.ViewEntitiesSelectorLocationOption,
    RVIPermission.ViewZiplineOnboarding,
    RVIPermission.UploadFilesZiplineOnboarding,
    RVIPermission.ManageZiplineContracts,
  ],
  [ManagerAccountRoleType.CorporateEditor]: [
    RVIPermission.ViewDashboard,
    RVIPermission.ViewReports,
    RVIPermission.ViewSpendByResidentPhase,
    RVIPermission.ViewSpendByDepartment,
    RVIPermission.ViewSpendByEnterpriseCategory,
    RVIPermission.ViewSpendByLocation,
    RVIPermission.ViewLocationsTable,
    RVIPermission.ViewLocationDetails,
    RVIPermission.CreateLocations,
    RVIPermission.ExportLocationDocuments,
    RVIPermission.ViewLocationNotices,
    RVIPermission.ManageLocationNotices,
    RVIPermission.ViewCorporateLevelServiceTerminationNotices,
    RVIPermission.ManageCorporateLevelServiceTerminationNotices,
    RVIPermission.ViewLocationVendorsAndProducts,
    RVIPermission.ViewVendorsTable,
    RVIPermission.CreateVendors,
    RVIPermission.ManageVendors,
    RVIPermission.ManageCorporateVendors,
    RVIPermission.ViewVendorDetails,
    RVIPermission.ViewCorporateLevelVendorDetails,
    RVIPermission.ViewCorporateLevelVendorsTable,
    RVIPermission.ManageCorporateVendors,
    RVIPermission.ViewCorporateVendorDocuments,
    RVIPermission.ManageCorporateVendorDocuments,
    RVIPermission.ViewContractsTable,
    RVIPermission.ViewCorporateLevelContracts,
    RVIPermission.ViewContractDetails,
    RVIPermission.ViewCorporateLevelContractDetails,
    RVIPermission.ManageContracts,
    RVIPermission.CreateCorporateLevelContracts,
    RVIPermission.ManageCorporateLevelContracts,
    RVIPermission.ManageContractLocationsDocuments,
    RVIPermission.ViewCorporateLevelContractDocuments,
    RVIPermission.ViewContractLineItem,
    RVIPermission.ExportContractLineItems,
    RVIPermission.ViewCorporateLevelContractProductsAndContractLineItems,
    RVIPermission.ManageContractLineItem,
    RVIPermission.ManageCorporateLevelContractLineItem,
    RVIPermission.ViewCorporateLevelContractLineItemPricing,
    RVIPermission.ManageCorporateLevelContractLineItemStatus,
    RVIPermission.ViewCorporateLevelContractLineItemsInTable,
    RVIPermission.ViewCorporateLevelContractLineItemDetails,
    RVIPermission.ManageContractLineItemLocations,
    RVIPermission.ViewContractLocations,
    RVIPermission.ManageTasks,
    RVIPermission.ViewCorporateLevelContractRenewalTask,
    RVIPermission.ManageCorporateLevelContractRenewalTask,
    RVIPermission.ViewTasks,
    RVIPermission.ChatOnCorporateLevelContractRenewalTask,
    RVIPermission.ViewEntitiesSelector,
    RVIPermission.ViewEntitiesSelectorAllOption,
    RVIPermission.ViewEntitiesSelectorCorporateOption,
    RVIPermission.ViewZiplineOnboarding,
    RVIPermission.UploadFilesZiplineOnboarding,
    RVIPermission.ManageZiplineContracts,
  ],
  [ManagerAccountRoleType.CorporateViewer]: [
    RVIPermission.ViewDashboard,
    RVIPermission.ViewReports,
    RVIPermission.ViewSpendByResidentPhase,
    RVIPermission.ViewSpendByDepartment,
    RVIPermission.ViewSpendByEnterpriseCategory,
    RVIPermission.ViewSpendByLocation,
    RVIPermission.ViewLocationsTable,
    RVIPermission.ViewLocationDetails,
    RVIPermission.ViewLocationNotices,
    RVIPermission.ViewCorporateLevelServiceTerminationNotices,
    RVIPermission.ViewLocationVendorsAndProducts,
    RVIPermission.ViewVendorsTable,
    RVIPermission.ViewVendorDetails,
    RVIPermission.ViewCorporateLevelVendorDetails,
    RVIPermission.ViewCorporateLevelVendorsTable,
    RVIPermission.ViewCorporateVendorDocuments,
    RVIPermission.ViewContractsTable,
    RVIPermission.ViewCorporateLevelContracts,
    RVIPermission.ViewContractDetails,
    RVIPermission.ViewCorporateLevelContractDetails,
    RVIPermission.ViewCorporateLevelContractDocuments,
    RVIPermission.ViewContractLineItem,
    RVIPermission.ViewCorporateLevelContractProductsAndContractLineItems,
    RVIPermission.ViewCorporateLevelContractLineItemPricing,
    RVIPermission.ViewCorporateLevelContractLineItemsInTable,
    RVIPermission.ViewCorporateLevelContractLineItemDetails,
    RVIPermission.ViewContractLocations,
    RVIPermission.ViewTasks,
    RVIPermission.ViewCorporateLevelContractRenewalTask,
    RVIPermission.ViewEntitiesSelector,
    RVIPermission.ViewEntitiesSelectorAllOption,
    RVIPermission.ViewEntitiesSelectorCorporateOption,
  ],
  [ManagerAccountRoleType.Member]: [
    RVIPermission.ViewDashboard,
    RVIPermission.ViewLocationsTable,
    RVIPermission.ViewVendorsTable,
    RVIPermission.ViewTasks,
    RVIPermission.ViewReports,
    RVIPermission.ViewContractsTable,
    RVIPermission.ViewContractLineItem,
    RVIPermission.ViewLocationNotices,
    RVIPermission.ViewLocationLevelVendorsTable,
    RVIPermission.ViewEntitiesSelector,
    RVIPermission.ViewEntitiesSelectorAllOption,
  ],
} as const;

export const RVILocationRolePermissionMap: Record<
  LocationRoleType,
  Readonly<PermissionType[]>
> = {
  Editor: [
    RVIPermission.ViewLocationDetails,
    RVIPermission.ViewLocationNotices,
    RVIPermission.ViewLocationLevelServiceTerminationNotices,
    RVIPermission.ViewContractDetails,
    RVIPermission.ViewLocationVendorsAndProducts,
    RVIPermission.ViewLocationLevelVendorDetails,
    RVIPermission.ViewLocationLevelVendorsTable,
    RVIPermission.ViewContractLineItem,
    RVIPermission.ViewLocationLevelContractProductsAndContractLineItems,
    RVIPermission.ViewLocationLevelContractLineItemDetails,
    RVIPermission.ViewLocationLevelContractLineItemsInTable,
    RVIPermission.ViewCorporateLevelContractLineItemsInTable,
    RVIPermission.ViewLocationLevelContractLineItemPricing,
    RVIPermission.ViewLocationLevelContractDetails,
    RVIPermission.ViewSpendByResidentPhase,
    RVIPermission.ViewSpendByLocation,
    RVIPermission.ViewVendorDetails,
    RVIPermission.ViewSpendByDepartment,
    RVIPermission.ViewLocationLevelContractRenewalTask,
    RVIPermission.ViewLocationLevelContractDocuments,
    RVIPermission.CreateVendors,
    RVIPermission.CreateLocationContracts,
    RVIPermission.ExportLocationDocuments,
    RVIPermission.ExportContractLineItems,
    RVIPermission.ManageLocationNotices,
    RVIPermission.ManageLocationLevelContractLineItemStatus,
    RVIPermission.ManageLocations,
    RVIPermission.ManageTasks,
    RVIPermission.ManageLocationVendors,
    RVIPermission.ViewLocationVendorDocuments,
    RVIPermission.ManageLocationVendorDocuments,
    RVIPermission.ManageContractLineItemLocations,
    RVIPermission.ManageLocationLevelServiceTerminationNotices,
    RVIPermission.ManageLocationLevelContracts,
    RVIPermission.ManageContracts,
    RVIPermission.ManageLocationLevelContractLineItem,
    RVIPermission.ManageLocationLevelContractRenewalTask,
    RVIPermission.ChatOnLocationLevelContractRenewalTask,
    RVIPermission.ViewEntitiesSelectorLocationOption,
    RVIPermission.ViewZiplineOnboarding,
    RVIPermission.UploadFilesZiplineOnboarding,
    RVIPermission.ManageZiplineContracts,
  ],
  Viewer: [
    RVIPermission.ViewLocationDetails,
    RVIPermission.ViewLocationNotices,
    RVIPermission.ViewLocationLevelServiceTerminationNotices,
    RVIPermission.ViewContractDetails,
    RVIPermission.ViewLocationLevelVendorDetails,
    RVIPermission.ViewLocationLevelVendorsTable,
    RVIPermission.ViewLocationVendorsAndProducts,
    RVIPermission.ViewVendorDetails,
    RVIPermission.ViewLocationVendorDocuments,
    RVIPermission.ViewLocationLevelContractDetails,
    RVIPermission.ViewContractLineItem,
    RVIPermission.ViewLocationLevelContractProductsAndContractLineItems,
    RVIPermission.ViewLocationLevelContractLineItemDetails,
    RVIPermission.ViewLocationLevelContractLineItemsInTable,
    RVIPermission.ViewCorporateLevelContractLineItemsInTable,
    RVIPermission.ViewLocationLevelContractLineItemPricing,
    RVIPermission.ViewSpendByResidentPhase,
    RVIPermission.ViewSpendByLocation,
    RVIPermission.ViewSpendByDepartment,
    RVIPermission.ViewLocationLevelContractRenewalTask,
    RVIPermission.ViewLocationLevelContractDocuments,
    RVIPermission.ViewEntitiesSelectorLocationOption,
  ],
} as const;

type ManagerAccountRoleWithLocationRoles = {
  id: string;
  manager_account_id: string;
  role: ManagerAccountRoleType;
  location_viewer_enabled: boolean;
  location_roles: {
    location_id: string;
    type: LocationRoleType;
  }[];
};

export type RVIPermissionUser = {
  id?: string;
  manager_account_roles:
    | ManagerAccountRoleWithLocationRoles[]
    | SerializeFrom<ManagerAccountRoleWithLocationRoles>[];
  user_roles: UserRole[] | SerializeFrom<UserRole>[];
};

export function canDoOnAccount(
  user: RVIPermissionUser,
  account: { id: string } | null,
  permission: PermissionType | PermissionType[]
): boolean {
  if (!account) return false;
  if (adminCanDo(user, AdminPermission.GoIntoRVIAccounts)) return true;

  const managerAccountRole = user.manager_account_roles.find(
    role => role.manager_account_id === account.id
  );

  // If no role for this account, return false
  if (!managerAccountRole) return false;

  const permissions =
    RVIRolePermissionMap[managerAccountRole.role as ManagerAccountRoleType];

  const authorized =
    permission instanceof Array
      ? permission.every(p => permissions.includes(p))
      : permissions.includes(permission);

  // If no authorized permissions, but the user has a role with location_viewer_enabled
  if (!authorized && managerAccountRole.location_viewer_enabled) {
    // check if the permission is in the location viewer role and authorize if so
    const locationViewerPermissions = RVILocationRolePermissionMap[
      LocationRoleType.Viewer
    ];

    return permission instanceof Array
      ? permission.every(p => locationViewerPermissions.includes(p))
      : locationViewerPermissions.includes(permission);
  }

  return authorized;
}

export function canDoOnAccountOrThrow(
  user: RVIPermissionUser,
  account: ManagerAccount | null,
  permission: PermissionType | PermissionType[],
  fallback?: string
): void {
  const authorized = canDoOnAccount(user, account, permission);

  if (authorized) return;

  if (fallback) {
    throw redirect(fallback);
  }

  const message = `You are not authorized to ${permission} in this Intelligence Account`;

  assertAuthorized(authorized, message);
}

export function canDoSomeOnAccount(
  user: RVIPermissionUser,
  account: { id: string } | null,
  permissions: PermissionType[]
): boolean {
  return permissions.some(permission =>
    canDoOnAccount(user, account, permission)
  );
}

export function canDoSomeOnAccountOrThrow(
  user: RVIPermissionUser,
  account: { id: string } | null,
  permissions: PermissionType[]
): void {
  const authorized = canDoSomeOnAccount(user, account, permissions);

  if (authorized) return;

  const message = `You are not authorized to go into this Intelligence Account's section`;

  assertAuthorized(authorized, message);
}

export function filterRolesByPermissions(
  requiredPermissions: PermissionType[]
): ManagerAccountRoleType[] {
  return Object.keys(RVIRolePermissionMap).filter(role =>
    requiredPermissions.some(permission =>
      RVIRolePermissionMap[role as ManagerAccountRoleType].includes(permission)
    )
  ) as ManagerAccountRoleType[];
}
